import { Icon } from "@chakra-ui/react";

type Props = {
  fill?: string;
};

function YoutubeIcon({ fill = "secondary.600" }: Props) {
  return (
    <Icon
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 100C22.4333 100 0 77.5667 0 50C0 22.4333 22.4333 0 50 0C77.5667 0 100 22.4333 100 50C100 77.5667 77.5667 100 50 100ZM50 6.66667C26.1 6.66667 6.66667 26.1 6.66667 50C6.66667 73.9 26.1 93.3333 50 93.3333C73.9 93.3333 93.3333 73.9 93.3333 50C93.3333 26.1 73.9 6.66667 50 6.66667Z"
        fill={fill}
      />
      <path
        d="M49.5333 68.3333C42.7667 68.5333 35.9667 68.1667 29.2667 67.2333C27.0333 66.6333 25.2333 64.9333 24.5667 62.7C23.2 54.2667 23.2 45.6667 24.5667 37.2333C25.2333 35 27 33.3 29.2333 32.7C35.8333 31.8333 42.5 31.4667 49.1667 31.6667C56.1 31.5 63.0333 31.8333 69.9333 32.7C72.1667 33.3 73.9333 35 74.6 37.2333C75.9333 45.6667 75.9333 54.2667 74.6 62.7C73.9333 64.9333 72.1667 66.6333 69.9333 67.2333C63.1667 68.1667 56.3667 68.5333 49.5333 68.3333ZM44.8 57.7L58.7333 49.9333L44.8 42.1667V57.7Z"
        fill={fill}
      />
    </Icon>
  );
}

// eslint-disable-next-line import/no-default-export
export default YoutubeIcon;
